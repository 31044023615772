.events-sec .event-item .price .percentage {
    font-style: normal;
    font-weight: 400;
    margin-left: 7px;
}
.events-sec .event-item .text-box button.btn-style-one .icon {
    display: inline-block;
}

.events-sec .event-item .text-box button.btn-style-one .icon svg {
    width: 18px;
    position: relative;
    top: -2px;
    margin-right: 10px;
}