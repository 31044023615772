/* .figure-ouch-nav.sticky-nav {
    position: fixed;
    background-color: #000;
    top: 0;
} */

.figure-ouch-nav .navbar-nav .social-menu {
    position: relative;
    display: flex;
    margin-left: 30px;
    margin-top: 6px;
    list-style: none;
  }
  
  a.btn-style-two.fixed-btn {
    position: fixed;
    bottom: 50px;
    right: 15px;
    z-index: 99;
    border: 1px solid #EF8A24;
    zoom: 0.8;
}
  
.figure-ouch-nav {
  position: absolute;
  top: 0px;
  z-index: 999;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 0 5px rgba( 0 0 0 / 10%);
}
  
  .figure-ouch-nav.sticky-nav {
    position: fixed;
      top: 0;
      background: rgba(255, 255, 255, 0.9);
      box-shadow: 7px 10px 20px rgba(91, 143, 222, 0.4), inset 8px 8px 16px rgba(223, 236, 255, 0.7), inset -15px -15px 30px rgba(188, 215, 255, 0.6);
      padding: 10px 0 0;
  }
  
  .figure-ouch-nav .inner-container {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    width: 100%;
    max-width: 80%;
    padding: 0px 15px;
    position: relative;
    align-items: center;
  }
  
  .figure-ouch-nav .navbar-nav .social-icon .icon-area {
    position: relative;
    margin: 0 5px;
  }
  
  .figure-ouch-nav .navbar-nav .social-icon .icon-area i img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
  
  .landing-dogfather .landing-marquee .marquee-container a {
    margin-right: 15px;
  }
  
  .figure-ouch-nav .inner-container .navbar-brand {
    width: 6%;
    padding: 0;
    margin-top: -3px;
    position: relative;
    top: -5px;
  }
  
  .navbar .button-head button .login-loader {
    padding: 0px 40px;
  }
  
  /* .navbar .button-head span:hover,
  .navbar .button-head button:hover {  
  background-color: #543c90;
  } */
  .img-trust-wallet {
    border-radius: 12px;
    margin-right: 26px;
  }
  
  .signup-modal .connect-wallat-signup .text-meta-mask-signup h5 span img {
    width: 5% !important;
  }
  
  
  .figure-ouch-nav .navbar-nav .connectbutton {
    background: no-repeat;
    border: none;
    color: #d0a535;
    text-decoration: underline;
  }
  
  .figure-ouch-nav .navbar-collapse {
    justify-content: center;
    width: 87%;
    left: 0;
    position: relative;
    padding-left: 19px;
  }
  
  .figure-ouch-nav .navbar-nav li {
    margin-right: 30px;
    transition: all 0.3s ease-in-out;
    padding-top: 0;
  }
  
  .figure-ouch-nav .navbar-nav li.nav-img {
    margin-right: 0;
    margin-left: 10px;
    padding-top: 11px;
  }
  
  .figure-ouch-nav .navbar-nav li.nav-img {
    margin-right: 0;
    margin-left: 10px;
    padding-top: 11px;
  }
  
  .figure-ouch-nav .navbar-nav li.nav-img .right-img {
    width: 178px;
  }
  
  .figure-ouch-nav .navbar-nav li a {
    color: var(--Primary, #231916);
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 100% */
    text-transform: uppercase;
  }
  .figure-ouch-nav .navbar-nav li a:hover,
  .figure-ouch-nav .navbar-nav li a.active {
    color: var(--Secondry, #EF8A24);
  }
  
  
  .figure-ouch-nav .inner-container .searchbox {
    position: absolute;
    margin-bottom: 0;
    top: -12px;
    right: 5px;
    display: flex;
    align-items: center;
}
.figure-ouch-nav .inner-container .searchbox #google_translate_element {
  position: relative;
}

.figure-ouch-nav .inner-container .searchbox #google_translate_element .goog-te-gadget {
  color: #fff !important;
}

.figure-ouch-nav .inner-container .searchbox #google_translate_element div#\:0\.targetLanguage {
  position: relative;
}
.figure-ouch-nav .inner-container .searchbox #google_translate_element .goog-te-gadget div#\:0\.targetLanguage {
  border-radius: 8px;
  text-transform: uppercase;
  background-color: #231916;
  border: 0;
  box-shadow: none;
  color: #fff;
  font-size: 14px;
  transition: .3s linear;
  position: relative;
  padding: 10px 4px;
  display: inline-block;
  height: 45px;
  line-height: 24px;
}

.figure-ouch-nav .inner-container .searchbox #google_translate_element .goog-te-gadget div#\:0\.targetLanguage img.goog-te-gadget-icon {
  display: none;
}

.figure-ouch-nav .inner-container .searchbox #google_translate_element .goog-te-gadget-simple .VIpgJd-ZVi9od-xl07Ob-lTBxed {
  color: #fff !important;
  font-size: 14px !important;
}

.figure-ouch-nav .inner-container .searchbox #google_translate_element .goog-te-gadget-simple .VIpgJd-ZVi9od-xl07Ob-lTBxed span {
  font-size: 13px;
}
.figure-ouch-nav .inner-container .searchbox .btn-style-one {
  margin-right: 20px;
}
  .figure-ouch-nav .inner-container .searchbox a.nav-link {
    top: 7px;
    display: inline-block;
    position: relative;
    overflow: hidden;
  }
  .figure-ouch-nav .inner-container .searchbox a.nav-link::before{
    content: "";
    position: absolute;
    left: -100px;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: #49AEFD;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }
  .figure-ouch-nav .inner-container .searchbox a.nav-link:hover::before,
  .figure-ouch-nav .inner-container .searchbox a.nav-link.active::before{
    opacity: 1;
    visibility: visible;
    left: 0;
  }
  
  @keyframes zoom-in-zoom-out {
    0% {
      transform: scale(1, 1);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1, 1);
    }
  }

  
  .figure-ouch-nav .navbar-collapse .close-btn-area {
    position: fixed;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: rgba(0 0 0 / 0.9);
    transition: all 0.4s ease-in-out;
  }
  
  .figure-ouch-nav .navbar-collapse.show .close-btn-area {
    left: 0;
  }
  
  .figure-ouch-nav .navbar-collapse .close-btn-area .close-button {
    background-color: transparent;
    padding: 0;
    top: 25px;
    right: 28px;
    background: transparent;
    transition: all 0.4s ease-in-out;
  }
  
  .figure-ouch-nav .navbar-collapse .close-btn-area .close-button i {
    font-size: 50px;
    font-style: normal;
  }
  
  .figure-ouch-nav .price-sec {
    position: absolute;
    right: 8%;
    width: 347px;
    top: 95px;
  }
  
  .figure-ouch-nav .price-sec ul {
    position: relative;
    display: flex;
    justify-content: space-between;
    list-style: none;
    background-color: rgba(244 245 252 / 85%);
    border-radius: 12px;
  }
  
  .figure-ouch-nav .price-sec ul li {
    width: 50%;
    padding: 0 15px;
    margin: 20px 0;
  }
  
  .figure-ouch-nav .price-sec ul li h4 {
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    color: #04061D;
    margin-bottom: 15px;
  }
  
  .figure-ouch-nav .price-sec ul li:nth-child(1) {
    border-right: 1px solid #04061D;
  }
  
  .figure-ouch-nav .price-sec ul li .price {
    display: flex;
  }
  
  .figure-ouch-nav .price-sec ul li .price .icon {
    margin-right: 10px;
    position: relative;
    top: -3px;
  }
  
  .figure-ouch-nav .price-sec ul li .price p {
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 0;
    color: #04061D;
  }
  
  .navbar-expand-lg .navbar-collapse .navbar-nav {
      width: 58%;
  }
  
  .navbar-expand-lg .navbar-collapse .mobile-menu-logo {
    display: none;
  }
  
  .figure-ouch-nav .navbar-nav li a .icon {
    margin-right: 10px;
  }
  
  .figure-ouch-nav .navbar-nav li a .icon svg {
    position: relative;
    top: -2px;
  }
  
  .figure-ouch-nav .navbar-nav li a .icon svg path {
    stroke: #293A49;
  }
  
  .figure-ouch-nav .navbar-nav li a:hover .icon svg path,
  .figure-ouch-nav .navbar-nav li a.active .icon svg path {
    stroke: #49AEFD;
  }

  .top-header {
    position: relative;
    background-color: #231916;
    padding: 10px 0;
    z-index: 22;
}

.top-header .left-contact-area {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.top-header .left-contact-area .contcat-box {
    width: 50%;
    display: flex;
    align-items: center;
}

.top-header .left-contact-area .contcat-box .icon {
    padding-right: 13px;
}

.top-header .left-contact-area .contcat-box a {
    color: #fff;
    font-size: 14px;
}
.top-header .right-contact-area {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: end;
}

.top-header .right-contact-area ul {
  margin-bottom: 0;
  list-style: none;
  display: flex;
  align-items: center;
}

.top-header .right-contact-area ul li {
  margin-left: 9px;
}
nav {
  margin-top: 10px;
}
.figure-ouch-nav .inner-container .searchbox #google_translate_element div#\:0\.targetLanguage option
.figure-ouch-nav .inner-container .searchbox #google_translate_element div#\:0\.targetLanguage option {
  background-color: #000;
  border: 1px solid #000;
  width: 100%;
  padding: 10px;
  display: block;
  color: #fff;
}
.figure-ouch-nav .inner-container .searchbox #google_translate_element div#\:0\.targetLanguage .goog-te-combo {
  margin: 2px 0;
  background-color: #fff;
}
.figure-ouch-nav .inner-container .searchbox #google_translate_element div#\:0\.targetLanguage option:hover {
  background-color: #000;
} 
@media(max-width: 1450px) {
  .navbar-expand-lg .navbar-collapse .navbar-nav {
    width: 72%;
}
}

  
  @media(max-width: 1199px) {
    .navbar-expand-lg .navbar-collapse .navbar-nav {
        width: 89%;
    }
    .figure-ouch-nav .navbar-collapse {
      padding-left: 15px;
    }
    .figure-ouch-nav .navbar-nav li {
        margin-right: 12px;
    }
    .figure-ouch-nav .inner-container {
      justify-content: center;
    }
  
    .figure-ouch-nav .inner-container .navbar-brand {
      padding: 0;
      margin-top: 0;
      text-align: center;
      margin-bottom: 0;
    }

  }
  
  @media(max-width: 1055px) {
    .figure-ouch-nav .navbar-nav li {
      margin-right: 17px;
    }
  }
  
  @media(max-width: 991px) {
    .navbar .button-head {
      padding-right: 0px;
    }
    .top-header .left-contact-area{
      position: relative;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
    }
    .top-header .left-contact-area .contcat-box {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .figure-ouch-nav .navbar-nav li a .icon {
      margin-right: 10px;
      display: inline-block;
    }
    .figure-ouch-nav {
        position: relative;
        top: -10px;
        z-index: 999;
        width: 100%;
        font-family: 'Poppins', sans-serif;
        background-color: #fff;
        transition: all 0.3s ease-in-out;
        box-shadow: 0 0 5px rgba( 0 0 0 / 10%);
    }
    .figure-ouch-nav .inner-container{
      max-width: 98%;
    }
    .top-header .right-contact-area {
      justify-content: center;
    }
  
    .figure-ouch-nav .navbar-nav li a {
      padding-bottom: 0 !important;
      margin-bottom: 0;
      border: none !important;
    }
  
    .navbar-expand-lg .navbar-collapse .mobile-menu-logo {
      display: flex;
      justify-content: space-between;
      padding: 20px 30px;
      border-bottom: 1px solid rgba(137, 151, 163, 0.1);
      margin-bottom: 20px;
    }
  
    .figure-ouch-nav .navbar-nav li {
      margin-right: 0;
      border-bottom: 1px solid rgba(137, 151, 163, 0.1);
      padding-bottom: 15px;
      margin-bottom: 15px;
      padding-left: 30px;
    }
  
    .navbar-expand-lg .navbar-collapse .mobile-menu-logo .navbar-brand {
      padding: 0;
    }
  
    .navbar-expand-lg .navbar-collapse .mobile-menu-logo .navbar-toggler {
      position: relative;
      top: auto;
      right: 0;
      transform: translateY(0);
    }
  
    .figure-ouch-nav .inner-container .navbar-brand {
      width: 18%;
      padding: 0;
      margin-top: 0;
      text-align: left;
      margin-bottom: 10px;
      padding-left: 27px;
  }
  
    .figure-ouch-nav .inner-container {
      justify-content: start;
    }
  
    .figure-ouch-nav .navbar-collapse.show .navbar-nav .social-icon {
      margin-top: 30px;
      text-align: center;
    }
  
    .figure-ouch-nav .price-sec {
      right: 2%;
      top: 52px;
    }
  
    .figure-ouch-nav .inner-container {
      margin: 0;
    }
  
    .navbar .nav-link {
      padding-right: 0px !important;
    }
  
    .figure-ouch-nav #navbarSupportedContent {
      margin-top: 0;
      margin-left: 0;
    }
  
    .figure-ouch-nav .inner-container .navbar-toggler {
      position: absolute;
      top: 50%;
      right: 5px;
      color: #368BF3;
      border-radius: 0;
      padding: 0;
      margin: 0;
      transform: translateY(-50%);
    }
  
    .figure-ouch-nav .inner-container .navbar-toggler .nav-links-btn {
      position: absolute;
      color: #fff;
      border-radius: 0;
      transform: translateY(-50%);
    }
  
    .figure-ouch-nav .navbar-collapse .navbar-nav {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      transition: all 0.5s ease-in-out;
    }
  
    .figure-ouch-nav .inner-container .searchbox {
      display: block;
      position: relative;
      padding: 25px 30px;
      text-align: center;
    }
  
    .figure-ouch-nav .navbar-collapse {
      width: 100%;
      position: fixed;
      top: 0;
      left: 0;
      background: #FFFFFF;
      box-shadow: 15px 15px 30px rgba(239, 138, 36, 0.1), inset 8px 8px 16px rgba(239, 138, 36, 0.3), inset -15px -15px 30px rgba(239, 138, 36, 0.2);
      border-radius: 0;
      padding: 0;
      z-index: 99;
      height: 100%;
    }
  
    .figure-ouch-nav .navbar-nav .social-menu {
      margin-top: 6px;
      justify-content: center;
    }
  
  
    .figure-ouch-nav .inner-container .navbar-toggler {
      z-index: 9;
    }
  
    .figure-ouch-nav .navbar-collapse .btn-style-one {
      margin: 9px 21px;
    }
  
  
    .figure-ouch-nav .inner-container .nav-links-btn {
      position: relative;
      top: 3% !important;
      right: 0px !important;
      text-align: left;
    }
  
    .figure-ouch-nav .searchbox {
      position: relative;
      margin-bottom: 0;
      top: 10px;
    }
  }
  
  @media(max-width: 576px) {
    .figure-ouch-nav .navbar-brand {
      width: 208px;
    }
    .top-header{
      display: none;
    }
    .figure-ouch-nav .inner-container .navbar-brand {
      width: 24%;
    }
    .figure-ouch-nav .inner-container .navbar-brand {
      width: 95px;
      margin: 0;
      padding: 0;
      position: relative;
      top: 1px;
    }
    .figure-ouch-nav .inner-container .searchbox {
      display: block;
    }
  
    .figure-ouch-nav .navbar-brand img {
      width: 65%;
      position: relative;
      left: 0;
      top: -1px;
    }
  
    .landing-dogfather .landing-marquee .marquee-container {
      font-size: 14px;
    }
  
    .figure-ouch-nav .price-sec {
      width: 91%;
    }
    .top-header .left-contact-area .contcat-box {
      width: 100%;
  }
  }
  
  
  @media (max-width: 380px) {
  
  
    .figure-ouch-nav .inner-container .navbar-toggler {
      right: 10px;
    }
  }
  
  /* @media(max-width: 768px){
  .navbar .button-head button{
    margin-left: 20px;
  }
  } */