.banner-area{
    position: relative;
    /* height: 100vh; */
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    background-image: url('../../static/images/banner-bg.jpg');
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100% 100%;
}
.banner-area .content {
    position: relative;
}

.banner-area .content h1 {
    color: var(--Primary, #231916);
    font-family: Poppins;
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    line-height: 65px; /* 118.75% */
    text-transform: uppercase;
    margin-bottom: 30px;
}

.banner-area .content p {
    color: var(--848484, #848484);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 20px;
}

.banner-area .content .register-form {
    position: relative;
}

.banner-area .content .register-form .group-form {
    position: relative;
    width: 100%;
    margin-bottom: 13px;
}

.banner-area .content .register-form .group-form input {
    width: 100%;
    padding: 16px 15px;
    border-radius: 8px;
    color: #848484;
    font-size: 14px;
}

.banner-area .content .register-form .group-form .btn-style-one {
    position: absolute;
    top: 50%;
    right: 6px;
    padding: 10px;
    font-size: 13px;
    transform: translateY(-50%);
}

.banner-area .img-area {
    position: relative;
    text-align: right;
}

.banner-area .img-area img {
    width: 73%;
}

.partners-sec{
    position: relative;
    padding: 25px 0;
    background-image: url('../../static/images/partner-bg.jpg');
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100% 100%;
    margin: 100px 0;
}
.partners-sec .sec-title h2 {
    color: #fff;
}
.partners-sec .item {
    position: relative;
    border-right: 2px solid #ffffff50;
    padding: 0 17px;
}
.events-sec {
    position: relative;
    padding: 50px 0;
    background-color: #fafafa;
}

.events-sec .title-area {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.events-sec .title-area .view-all {
    margin-bottom: 0;
    color: var(--Secondry, #EF8A24);
    font-family: "Poppins";
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    transition: all 0.3s ease-in-out;
}
.events-sec .title-area .view-all:hover{
    color: #231916;
}
.events-sec .event-item {
    position: relative;
    background-color: transparent;
    padding: 16px;
    border-radius: 16px;
    margin-bottom: 0;
    box-shadow: none;
    border-bottom: 1px solid #ccc;
    border-radius: 0;
}
.banner-area .video-area {
    width: 100%;
    height: 92vh;
}
.banner-area video {
    min-width: 100%;
    width: 100%;
    min-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    -o-object-fit: fill;
    object-fit: fill;
    height: 100%;
}
.banner-area .col-12 {
    padding: 0;
}
.add-media-modal .modal-content .event-item .img-box{
    position: relative;
    width: 100%;
    height: 350px;
}
.add-media-modal .modal-content .event-item .img-box img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 8px;
}
.events-sec .event-item .img-box {
    position: relative;
}

.events-sec .event-item .img-box img {
    border-radius: 8px;
    width: 100%;
    /* height: 300px; */
}
.events-sec .event-item .text-box a, .add-media-modal .modal-content .event-item .text-box a {
    color: #EF8A24;
    word-wrap: break-word;
}
.events-sec.style-two .event-item .img-box img {
    width: 100%;
    height: 200px;
}
.events-sec .event-item .text-box,
.add-media-modal .modal-content .event-item .text-box{
    position: relative;
    padding: 13px 0 0;
}
.events-sec .slick-slide {
    padding: 30px 20px;
}
.events-sec .event-item .text-box .top-box,
.add-media-modal .modal-content .event-item .text-box .top-box{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;
    text-align: left;
}

.events-sec .event-item .text-box .top-box span,
.add-media-modal .modal-content .event-item .text-box .top-box span {
    color: var(--Primary, #23191670);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    text-align: left;
}

.events-sec .event-item .text-box h3,
.add-media-modal .modal-content .event-item .text-box h3 {
    color: var(--Primary, #231916);
    font-family: "Poppins";
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    text-align: left;
}

.events-sec .event-item .text-box p,
.add-media-modal .modal-content .event-item .text-box p {
    color: var(--Primary, #23191670);
    font-family: "Poppins";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
}
.events-sec .slick-slider .slick-arrow {
    background-image: url('../../static/images/arrow.svg');
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100% 100%;
    width: 38px;
    height: 38px;
}
.events-sec .slick-slider .slick-arrow.slick-prev {
    transform: rotate(180deg);
}
.events-sec .slick-slider .slick-arrow::before {
    display: none;
}

.counter-sec{
    position: relative;
    /* padding: 25px 0; */
    background-image: url('../../static/images/counter-bg.png');
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100% 100%;
    background-color: #f1963a;
}
.counter-sec .title-area {
    position: relative;
    padding-top: 0;
    border-right: 1px solid #ffffff60;
}

.counter-sec .title-area h4 {
    color: #FFF;
    font-family: "Poppins";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
    position: relative;
}

.counter-sec .title-area:before {
    content: "";
    position: absolute;
    left: -8px;
    top: 1px;
    width: 20px;
    height: 20px;
    background-color: #000;
    border-radius: 50%;
}

.counter-sec .counter-inner {
    position: relative;
    text-align: center;
    border-right: 1px solid #ffffff60;
}

.counter-sec .counter-inner.last-counter{
    border-right: none;
}

.counter-sec .counter-inner .counter-start {
    position: relative;
}

.counter-sec .counter-inner .counter-start h1 {
    color: #FFF;
    font-family: "Poppins";
    font-size: 47px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    margin-bottom: 0;
}

.counter-sec .counter-inner .counter-start p {
    margin-bottom: 0;
    color: #fff;
    font-size: 13px;
}
body {
    top: 0 !important;
}
.upcoming-sec {
    position: relative;
    padding: 100px 0;
    background-color: #fafafa;
}
iframe#\:2\.container {
    display: none;
    height: 0 !important;
    position: absolute;
}
.upcoming-sec .event-item, .add-media-modal .modal-content .event-item {
    position: relative;
    border-bottom: 1px solid #ccc;
    padding-bottom: 20px;
}

.upcoming-sec .event-item .img-box {
    position: relative;
    padding-right: 0;
}

.upcoming-sec .event-item .img-box img {
    box-shadow: 0 0 15px rgba(0 0 0 /20%);
    border-radius: 5px;
}

.upcoming-sec .event-item .text-box {
    position: relative;
    padding-top: 40px;
}

.upcoming-sec .event-item .text-box .top-box {
    position: relative;
    display: flex;
    justify-content: space-between;
}

.upcoming-sec .event-item .text-box .top-box span {
    color: var(--Primary, #23191670);
    font-family: "Poppins";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}

.upcoming-sec .event-item .text-box h3 {
    color: var(--Primary, #231916);
    font-family: "Poppins";
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    margin: 10px 0 20px;
}

.upcoming-sec .event-item .text-box h3 a img {
    margin-top: -3px;
}

.upcoming-sec .event-item .text-box a img {
    margin-top: -35px;
}

.upcoming-sec .event-item .text-box p {
    color: var(--Primary, #23191670);
    font-family: "Poppins";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.upcoming-sec .event-item .text-box .price {
    display: block;
    color: var(--Primary, #23191670);
    font-family: "Poppins";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.upcoming-sec .event-item .text-box .btn-style-one {
    margin-top: 20px;
}
.upcoming-sec .event-item .text-box .price .full-price {
    opacity: 0.7;
    color: #FF3B00;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: line-through;
}

.upcoming-sec .event-item .text-box .price .discount-price {
    color: #231916;
    font-weight: 600;
    font-style: normal;
}

.concert-sec{
    position: relative;
    padding: 25px 0;
    background-image: url('../../static/images/concept-bg.png');
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100% 100%;
    background-color: #403734;
}
.concert-sec .title-area {
    position: relative;
}

.concert-sec .title-area h4 {
    color: var(--White, #FFF);
    font-family: "Poppins";
    font-size: 27px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px; /* 140% */
    text-transform: uppercase;
}

.concert-sec .title-area p {
    font-size: 15px;
    margin-bottom: 0;
}

.concert-sec .btn-area {
    position: relative;
    text-align: right;
}
.vision-sec {
    position: relative;
    padding: 100px 0;
    background-color: #fafafa;
}

.vision-sec .text-box {
    position: relative;
    padding-top: 40px;
    padding-right: 25px;
}

.vision-sec .text-box h3 {
    color: var(--Primary, #231916);
    font-family: "Poppins";
    font-size: 23px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
}

.vision-sec .text-box p {
    color: var(--Primary, #23191680);
    text-align: justify;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 40px;
}
.upcoming-home .row{
    align-items: center;
}
.img-sec {
    position: relative;
}

.img-sec .col-12 {
    padding: 0;
}

.img-sec .col-12 img {
    width: 100%;
}
@media(max-width: 1366px){
    .events-sec .event-item{
        /* min-height: 556px; */
    }
}
@media(max-width: 991px) {
    .banner-area{
        height: 100%;
    }
    .upcoming-sec .event-item .text-box {
     text-align: center;
    }
    .upcoming-sec .event-item .img-box {
        position: relative;
        padding-right: 0;
        margin: 0 auto;
        width: 100%;
        text-align: center;
    }
    .concert-sec .title-area,
    .concert-sec .btn-area {
        text-align: center;
    }
    .concert-sec .btn-area{
        margin-top: 10px;
    }
}
@media(max-width: 767px){
    .banner-area .video-area {
        width: 100%;
        height: 58vh;
    }
    .title-area {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center !important;
        text-align: center !important;
        flex-wrap: wrap !important;
    }
    .title-area .sec-title {
        width: 100%;
        text-align: center !important;
        margin-bottom: 0;
        padding-bottom: 10px;
    }
    .counter-sec .title-area:before{
        display: none;
    }
    .counter-sec .title-area,
    .counter-sec .counter-inner{
        border-right: none;
        margin-bottom: 20px;
    }
}
@media(max-width: 555px){
    .banner-area .content h1{
        font-size: 25px;
        line-height: 31px;
    }
    .banner-area .video-area {
        width: 100%;
        height: 24vh;
    }
}
