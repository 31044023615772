.voting-single-sec {
    position: relative;
    padding: 100px 0;
    background-color: #fafafa;
}

.voting-single-sec .title-area {
    margin-bottom: 64px;
}

.voting-single-sec .title-area h2 {
    color: var(--Primary, #231916);
    font-family: Poppins;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
}

.voting-single-sec .title-area p {
    color: var(--Primary, #23191670);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.voting-single-sec .voting-box {
    position: relative;
    background-color: #fff;
    padding: 30px 30px 0;
    border-radius: 16px;
    margin-bottom: 30px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
}

.voting-single-sec .voting-box .voting-item {
    position: relative;
    background-color: #fff;
    border-radius: 16px;
    margin-bottom: 30px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
}

.voting-single-sec .voting-box .voting-item .img-box {
    position: relative;
}

.voting-single-sec .voting-box .voting-item .img-box img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.voting-single-sec .voting-box .voting-item .text-box {
    border-radius: 8px;
    width: 100%;
}

.voting-single-sec .voting-box .voting-item .text-box h3 {
    color: #231916;
    color: var(--Primary, #231916);
    font-family: "Poppins";
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
}

.voting-single-sec .voting-box .voting-item .text-box p {
    color: #23191670;
    color: var(--Primary, #23191670);
    font-family: "Poppins";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.voting-single-sec .voting-box .voting-item .text-box .btn-style-one {
    margin-top: 17px;
}

.voting-single-sec .voting-box .voting-item .text-box label {
    color: var(--Primary, #231916);
    font-family: Poppins;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
}

.voting-single-sec .voting-box .voting-item .text-box .progress {
    background-color: #D9D9D9 !important;
    height: 12px !important;
}

.voting-single-sec .voting-box .voting-item .text-box .progress .progress-bar {
    background-color: #231916;
}