.inner-page-banner {
    position: relative;
    padding: 116px 0 30px;
    text-align: center;
    background-color: #fafafa;
}
.inner-page-banner h1 {
    color: var(--Primary, #231916);
    font-family: "Poppins";
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 37.5% */
    text-transform: capitalize;
    margin-bottom: 10px;
}

.inner-page-banner .bredcamb {
    display: flex;
    align-items: center;
    justify-content: center;
}

.inner-page-banner .bredcamb li {
    margin: 0 3px;
    color: #231916;
    font-size: 14px;
}

.inner-page-banner .bredcamb li .bredcamb-link {
    color: #23191690;
    font-size: 16px;
}

.inner-page-banner .bredcamb li .bredcamb-link.active {
    color: #231916;
}
.concert-sec.style-two {
    margin: 0 0 100px;
}
.add-media-modal {
    max-width: 555px !important;
    top: 5% !important;
}
section.events-sec.gallery-page {
    min-height: 39vh;
}
.add-media-modal .modal-content {
    background-color: #fff !important;
    padding: 20px !important;
}
.add-media-modal .modal-content .event-item .img-box video {
    width: 100%;
    height: 100%;
    border-radius: 8px;
}

.add-media-modal .modal-content .modal-header .modal-title .btn-style-one {
    padding: 5px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    line-height: 20px;
    display: block;
}
