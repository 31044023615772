.login-page {
    width: 100%;
    position: relative;
    background-color: #fff;
  }
  .login-page .form-area {
    display: flex;
    align-items: center;
    height: 85vh;
  }
  .login-page .form-block{
    position: relative;
  }
  .login-page .form-block{
    position: relative;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 0px 40px 10px rgba(0, 0, 0, 0.05);
  }
  .input-group, .login-page .form-block .form-group,
  .main-modal .modal-content .modal-body .form .form-group {
    margin-bottom: -2px;
    position: relative;
    width: 100%;
  }
  .login-page .form-block .btn-group{
    margin-top: 25px;
  }
  .login-page .form-block h3 {
    color: #000;
    font-family: "Poppins";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    margin-bottom: 0;
  }
  
  
  .login-page .form-block .form-group label,
  .main-modal .modal-content .modal-body .form .form-group label {
    color: #0F001A;
    color: var(--Text-color, #0F001A);
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    text-transform: capitalize;
    position: relative;
    background-color: #fff;
    margin-bottom: 0;
    top: 16px;
    z-index: 2;
    margin-left: 15px;
    padding: 5px 5px 0;
  }
  
  .payment-method-page .left-content-area .form-block .form-group .MuiInput-root,
  .main-modal .modal-content .modal-body .form .form-group .MuiFormControl-root .MuiInputBase-root input,
  .main-modal .modal-content .modal-body .form .form-group .MuiFormControl-root .MuiInputBase-root .MuiSelect-select.MuiSelect-select,
  .main-modal .modal-content .modal-body .form .form-group input[type=file] {
    color: #1E1E1E;
    color: var(--Text, #1E1E1E);
    font-family: "Poppins";
    font-style: normal;
  }
  .input-group input, .form-group .MuiFormControl-root input,
  .main-modal .modal-content .modal-body .form .form-group .MuiFormControl-root .MuiInputBase-root input,
  .main-modal .modal-content .modal-body .form .form-group .MuiFormControl-root .MuiInputBase-root .MuiSelect-select.MuiSelect-select,
  .main-modal .modal-content .modal-body .form .form-group input[type=file] {
    color: var(--Text, #1E1E1E);
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    border-radius: 8px;
    border: 1.2px solid #B4BEC8;
    height: 24px;
    padding: 10px 15px;
  }

  .form-group select {
    color: #1E1E1E;
    color: var(--Text, #1E1E1E);
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    border-radius: 8px;
    border: 1.2px solid #B4BEC8;
    padding: 10px 15px;
    background-color: transparent;
    height: 50px;
    width: 100%;
}
.events-sec .event-item .text-box button.btn-style-one {
  margin-left: 0px;
}
.form-group.btn-group.text-center {
  text-align: center;
  width: 100%;
}

.form-group.btn-group.text-center button.btn-style-one {
  margin: 0 auto;
}
  .main-modal .modal-content .modal-body .form .form-group .MuiFormControl-root .MuiInputBase-root input#controllable-states-demo {
      border: none;
  }
  
  .main-modal .modal-content .modal-body .form .form-group .MuiFormControl-root .MuiInputBase-root  button {
      color: #000 !important;
  }
  
  .main-modal .modal-content .modal-body .form .form-group .MuiFormControl-root .MuiInputBase-root button svg {
      fill: #000;
  }
  .login-page .form-block .form-group .MuiFormControl-root::before, 
  .login-page .form-block .form-group .MuiFormControl-root::after {
    display: none;
  }
  
  .login-page .form-block .title-area {
      border-radius: 10px 10px 0px 0px;
      background-color: var(--Primary, #231916);
      width: 100%;
      text-align: center;
      padding: 10px;
      color: #fff;
  }
  
  .login-page .form-block .title-area p.login-title {
      color: #fff !important;
      text-align: center;
      
  /* Title2 */
      font-family: "Poppins";
      font-size: 26px;
      font-style: normal;
      font-weight: 600;
      line-height: 40px; /* 100% */
      text-transform: capitalize;
  }
  
  .login-page .form-block form {
      padding: 0 37px 45px;
  }
  .login-page .form-block .form-group.links-group {
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .MuiInput-root::before, .MuiInput-root::after {
    display: none;
    }
  .login-page .form-block .form-group.links-group a, .login-page .form-block .form-group.links-group p {
    color: var(--Primary, #23191680) !important;
    
  /* Placeholder */
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 185.714% */
  }
  
  .login-page .form-block .form-group.links-group p a {
    color: var(--Primary, #EF8A24) !important;
    margin-left: 5px;
  }